import React, { useContext, useState } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    ListItem,
    List,
    IconButton,
    Dialog,
    ListItemSecondaryAction
} from "@material-ui/core";
import { MessagingContext } from "./MessagingContext";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { OfficeContext } from "../OfficeContext";
import firebase from "../../../firebase";
import { appContext } from "../../../contexts/app";
import insyncHttps from "../../../insyncHttps/insyncHttps";

export default function(props) {
    const { users, loading } = useContext(MessagingContext);
    const [addUserOpen, setAddUserOpen] = useState(false);
    return (
        <Card>
            <CardHeader title="Messaging Users" />
            <CardContent>
                {loading ? (
                    <CircularProgress size={30} />
                ) : (
                    <React.Fragment>
                        <List
                            style={{ maxHeight: "400px", overflowY: "scroll" }}
                        >
                            {users &&
                                getSortedUserIds(users).map(userId => {
                                    return (
                                        <UserListItem
                                            key={userId}
                                            userId={userId}
                                            user={users[userId]}
                                        />
                                    );
                                })}
                        </List>
                        <IconButton
                            onClick={() => {
                                setAddUserOpen(true);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </React.Fragment>
                )}
            </CardContent>
            <Dialog
                open={addUserOpen}
                onClose={() => {
                    setAddUserOpen(false);
                }}
            >
                <AddUser
                    handleClose={() => {
                        setAddUserOpen(false);
                    }}
                />
            </Dialog>
        </Card>
    );
}

function UserListItem(props) {
    const user = props.user;
    const userId = props.userId;
    const [editUserOpen, setEditUserOpen] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { getMessaging } = useContext(MessagingContext);
    const [loading, setLoading] = useState(false);
    return (
        <React.Fragment>
            <ListItem
                button
                divider
                onClick={() => {
                    setEditUserOpen(true);
                }}
            >
                {user.name}
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => {
                            setLoading(true);
                            insyncHttps.messaging
                                .removeUser({ officeId, userId })
                                .then(result => {
                                    setLoading(false);
                                    getMessaging();
                                })
                                .catch(err => {
                                    console.error(err);
                                    setLoading(false);
                                    error.setMessage(err.message);
                                });
                        }}
                    >
                        <DeleteIcon />
                        {loading && <CircularProgress size={10} />}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog
                open={editUserOpen}
                onClose={() => {
                    setEditUserOpen(false);
                }}
            >
                <EditUser
                    handleClose={() => {
                        setEditUserOpen();
                    }}
                    userId={userId}
                    user={user}
                />
            </Dialog>
        </React.Fragment>
    );
}

function getSortedUserIds(users) {
    return Object.keys(users).sort((a, b) => {
        if (users[a].name.toLowerCase() > users[b].name.toLowerCase()) {
            return 1;
        }
        if (users[a].name.toLowerCase() < users[b].name.toLowerCase()) {
            return -1;
        }
        return 0;
    });
}
