import firebase from "../../firebase";

export default function({
    officeId,
    stageId,
    name,
    backgroundColor,
    textColor
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingAdmin",
        funcName: "updateStage",
        data: {
            officeId,
            stageId,
            name,
            backgroundColor,
            textColor
        }
    });
}
