import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    CardActions,
    Grid,
    IconButton,
    Menu,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { SketchPicker } from "react-color";
import { OfficeContext } from "../../OfficeContext";
import firebase from "../../../../firebase";
import { appContext } from "../../../../contexts/app";
import { PagingContext } from "../PagingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default React.forwardRef((props, ref) => {
    const handleClose = props.handleClose;
    const { error } = useContext(appContext);
    const { getPaging } = useContext(PagingContext);
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const [rooms, setRooms] = useState([
        {
            name: "newRoom"
        }
    ]);

    const addRoomToList = () => {
        setRooms([
            ...rooms,
            {
                name: "newRoom"
            }
        ]);
    };

    const removeRoomFromList = () => {
        if (rooms.length > 0) {
            setRooms(rooms.slice(0, rooms.length - 1));
        }
    };

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Add Rooms" />
            <CardContent>
                <div style={{ margin: "auto" }}>
                    <IconButton
                        onClick={() => {
                            addRoomToList();
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            removeRoomFromList();
                        }}
                    >
                        <BackspaceIcon />
                    </IconButton>
                </div>
                <Grid
                    container
                    style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                    {rooms.map((room, index) => {
                        return (
                            <Room
                                key={index}
                                room={rooms[index]}
                                handleChange={room => {
                                    const newRooms = [...rooms];
                                    newRooms[index] = { ...room };
                                    setRooms(newRooms);
                                }}
                            />
                        );
                    })}
                </Grid>
                <Button
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.paging
                            .addRoomsToOffice({ officeId, rooms })
                            .then(result => {
                                getPaging();
                                setLoading(false);
                                handleClose();
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Submit
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardContent>
        </Card>
    );
});

function Room(props) {
    const room = props.room;
    const handleChange = props.handleChange;

    return (
        <Card style={{ width: "100%" }}>
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    align="center"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <Grid container justify="center" align="center">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="name"
                                    value={room.name}
                                    onChange={e => {
                                        room.name = e.target.value;
                                        handleChange(room);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
