import React, { useState, useContext } from "react";
import {
    CardContent,
    Card,
    CardHeader,
    Grid,
    Button,
    CircularProgress
} from "@material-ui/core";

import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { PatientTrackingContext } from "../PatientTrackingContext";
import ModifyStage from "./ModifyStage";

export default React.forwardRef((props, ref) => {
    const stageId = props.stageId;
    const { getPatientTracking, stages } = useContext(PatientTrackingContext);
    const [stage, setStage] = useState({ ...stages[stageId] });
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);

    const handleClose = props.handleClose;
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Edit Stage" />
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    align="center"
                    justify="center"
                    alignItems="stretch"
                >
                    <ModifyStage
                        stage={stage}
                        handleChange={s => {
                            setStage({ ...s });
                        }}
                    ></ModifyStage>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLoading(true);
                                insyncHttps.patientTracking
                                    .updateStage({
                                        officeId,
                                        stageId,
                                        ...stage
                                    })
                                    .then(result => {
                                        setLoading(false);
                                        getPatientTracking();
                                        handleClose();
                                    })
                                    .catch(err => {
                                        console.error(err);
                                        setLoading(false);
                                        error.setMessage(err.message);
                                    });
                            }}
                        >
                            Submit
                        </Button>
                        {loading && <CircularProgress size={10} />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
