import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    Grid,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";
import { PagingContext } from "../PagingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import ModifyMeaningButton from "./ModifyMeaningButton";

export default React.forwardRef((props, ref) => {
    const handleClose = props.handleClose;
    const { error } = useContext(appContext);
    const { getPaging } = useContext(PagingContext);
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const [meaningButtons, setMeaningButtons] = useState([
        {
            name: "new meaning"
        }
    ]);

    const addMeaningButtonToList = () => {
        setMeaningButtons([
            ...meaningButtons,
            {
                name: "new meaning"
            }
        ]);
    };

    const removeMeaningButtonFromList = () => {
        if (meaningButtons.length > 0) {
            setMeaningButtons(
                meaningButtons.slice(0, meaningButtons.length - 1)
            );
        }
    };

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Add MeaningButtons" />
            <CardContent>
                <div style={{ margin: "auto" }}>
                    <IconButton
                        onClick={() => {
                            addMeaningButtonToList();
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            removeMeaningButtonFromList();
                        }}
                    >
                        <BackspaceIcon />
                    </IconButton>
                </div>
                <Grid
                    container
                    style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                    {meaningButtons.map((meaningButton, index) => {
                        return (
                            <ModifyMeaningButton
                                key={index}
                                meaningButton={meaningButtons[index]}
                                handleChange={meaningButton => {
                                    const newMeaningButtons = [
                                        ...meaningButtons
                                    ];
                                    newMeaningButtons[index] = {
                                        ...meaningButton
                                    };
                                    setMeaningButtons(newMeaningButtons);
                                }}
                            />
                        );
                    })}
                </Grid>
                <Button
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.paging
                            .addMeaningButtonsToOffice({
                                officeId,
                                meaningButtons
                            })
                            .then(result => {
                                getPaging();
                                setLoading(false);
                                handleClose();
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Submit
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardContent>
        </Card>
    );
});
