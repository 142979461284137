import React, { useState, useContext } from "react";

import Controls from "./Controls";
import useVisitReport1 from "./useVisitReport1";
import VisitReport1Context from "./VisitReport1Context";
import { Card, CardContent } from "@material-ui/core";
import View1 from "./views/View1/View1";
import View2 from "./views/View2/View2";

export default function(props) {
    const reportCtx = useVisitReport1();

    return (
        <Card>
            <CardContent>
                <VisitReport1Context.Provider value={reportCtx}>
                    <Controls></Controls>
                    <View1></View1>
                    {/* <View2></View2> */}
                </VisitReport1Context.Provider>
            </CardContent>
        </Card>
    );
}
