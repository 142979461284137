import React, { useState, useContext, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    MenuItem,
    TextField,
    Grid,
    Button,
    CircularProgress
} from "@material-ui/core";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { OfficeContext } from "../../OfficeContext";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { appContext } from "../../../../contexts/app";

export default function(props) {
    const { visitDisplaySettings, loading, getPatientTracking } = useContext(
        PatientTrackingContext
    );

    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const [counterType, setCounterType] = useState(
        visitDisplaySettings.counterType || "countDown"
    );

    const [reqeusting, setRequesting] = useState(false);

    useEffect(() => {
        setCounterType(visitDisplaySettings.counterType || "countDown");
    }, [visitDisplaySettings]);

    const modified = counterType !== visitDisplaySettings.counterType;
    return (
        <Card>
            <CardHeader title={"Visit Display Settings"}></CardHeader>
            <CardContent>
                {loading ? (
                    <CircularProgress></CircularProgress>
                ) : (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label="Timer Display Type"
                                    value={counterType}
                                    onChange={e => {
                                        setCounterType(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"countDown"}>
                                        Count Down
                                    </MenuItem>
                                    <MenuItem value={"countUp"}>
                                        Count Up
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Button
                                disabled={!modified}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setRequesting(true);
                                    insyncHttps.patientTracking
                                        .updateVisitDisplaySettings({
                                            officeId,
                                            counterType
                                        })
                                        .then(e => {
                                            setRequesting(false);
                                            getPatientTracking();
                                        })
                                        .catch(e => {
                                            setRequesting(false);
                                            error.setMessage(e);
                                        });
                                }}
                            >
                                update
                            </Button>
                            {reqeusting && (
                                <CircularProgress></CircularProgress>
                            )}
                        </Grid>
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
}
