import React, { useContext, useState, useEffect } from "react";

import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    CardActions,
    Dialog,
    Button
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import AddStages from "./AddStages";
import StageListItem from "./StageListItem";

import { PatientTrackingContext } from "../PatientTrackingContext";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const { stages, loading, getPatientTracking } = useContext(
        PatientTrackingContext
    );
    const { officeId } = useContext(OfficeContext);

    const [addStagesOpen, setAddStagesOpen] = useState(false);
    const [orderedStageIds, setOrderedStageIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    function moveStageUp(stageId) {
        const currentIndex = orderedStageIds.indexOf(stageId);
        if (currentIndex > 0) {
            orderedStageIds.splice(currentIndex, 1);
            orderedStageIds.splice(currentIndex - 1, 0, stageId);
            setOrderModified(true);
            setOrderedStageIds([...orderedStageIds]);
        }
    }

    function moveStageDown(stageId) {
        const currentIndex = orderedStageIds.indexOf(stageId);
        if (currentIndex < orderedStageIds.length - 1) {
            orderedStageIds.splice(currentIndex, 1);
            orderedStageIds.splice(currentIndex + 1, 0, stageId);
            setOrderedStageIds([...orderedStageIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        setOrderModified(false);
        if (stages) {
            setOrderedStageIds(getSortedStageIdsAlphabetical(stages));
        }
    }, [stages]);

    return (
        <Card>
            <CardHeader title="Stages" />
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    justify="center"
                    style={{ height: 300, overflowY: "scroll" }}
                >
                    {loading ? (
                        <CircularProgress size={20} />
                    ) : (
                        orderedStageIds.map(stageId => {
                            const stage = stages[stageId];
                            if (!stages[stageId]) {
                                return null;
                            }
                            return (
                                // <div>{JSON.stringify(stage)}</div>
                                <StageListItem
                                    key={stageId}
                                    stage={stage}
                                    stageId={stageId}
                                    moveStageUp={moveStageUp}
                                    moveStageDown={moveStageDown}
                                />
                            );
                        })
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <IconButton
                    onClick={() => {
                        setAddStagesOpen(true);
                    }}
                >
                    <AddIcon />
                </IconButton>
                {orderModified && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSaveOrderLoading(true);
                            insyncHttps.patientTracking
                                .reorderStages({
                                    officeId,
                                    stageIds: orderedStageIds
                                })
                                .then(result => {
                                    setSaveOrderLoading(false);
                                    getPatientTracking();
                                })
                                .catch(err => {
                                    setSaveOrderLoading(false);
                                    getPatientTracking();
                                });
                        }}
                    >
                        save order
                        {saveOrderLoading && (
                            <CircularProgress size={10}></CircularProgress>
                        )}
                    </Button>
                )}
            </CardActions>
            <Dialog
                open={addStagesOpen}
                onClose={() => {
                    setAddStagesOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <AddStages
                            handleClose={() => {
                                setAddStagesOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Card>
    );
}

function getSortedStageIds(stages) {
    return Object.keys(stages)
        .filter(stageId => {
            return Object.keys(stages).includes(stageId);
        })
        .sort((a, b) => {
            const stageA = { ...stages[a] };
            const stageB = { ...stages[b] };
            stageA.listPosition = stageA.listPosition ? stageA.listPosition : 0;
            stageB.listPosition = stageB.listPosition ? stageB.listPosition : 0;

            return stageA.listPosition - stageB.listPosition;
        });
}

function getSortedStageIdsAlphabetical(stages) {
    return Object.keys(stages)
        .filter(stageId => {
            return Object.keys(stages).includes(stageId);
        })
        .sort((a, b) => {
            const nameA = stages[a].name;
            const nameB = stages[b].name;
            return nameA.localeCompare(nameB);
        });
}
