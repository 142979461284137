import React, { useContext } from "react";
import ColumnLayoutContext from "./ColumnLayoutContext";
import {
    Card,
    CardContent,
    Checkbox,
    Grid,
    IconButton
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const baseStyle = {};
const disabledStyle = { backgroundColor: "grey" };

export default function(props) {
    const { columnId } = props;
    const { localState } = useContext(ColumnLayoutContext);
    const column = localState.trackerColumns[columnId];

    const itemStyle = (() => {
        return column.enabled ? baseStyle : { ...baseStyle, ...disabledStyle };
    })();

    return (
        <Card>
            <CardContent style={itemStyle}>
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item xs={2}>
                        <Checkbox
                            checked={column.enabled}
                            onChange={(e, v) => {
                                localState.setColumnEnabled(columnId, v);
                            }}
                        ></Checkbox>
                    </Grid>
                    <Grid item xs={8}>
                        <div> {column.name}</div>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            onClick={() => {
                                localState.moveColumnDown(columnId);
                            }}
                        >
                            <ArrowDownwardIcon></ArrowDownwardIcon>
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                localState.moveColumnUp(columnId);
                            }}
                        >
                            <ArrowUpwardIcon></ArrowUpwardIcon>
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
