import React, { useState, useContext } from "react";
import firebase from "../../../../firebase";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Dialog,
    List,
    ListItem,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    ListItemSecondaryAction,
    IconButton,
    ListItemText,
    Tooltip,
    Modal,
    CircularProgress
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const licenses = props.licenses;
    const officeId = props.officeId;
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [newLicenseName, setNewLicenseName] = useState("");

    const { subscriptions } = useContext(BillingContext);
    return (
        <Card>
            <CardHeader title="LICENSES" />
            <CardContent style={{ height: "200px", overflowY: "auto" }}>
                <LicenseList licenses={licenses} />
            </CardContent>
        </Card>
    );
}

function LicenseList(props) {
    const { officeId } = useContext(OfficeContext);
    const { subscriptions } = useContext(BillingContext);
    const licenses = props.licenses;
    const [viewedLicenseId, setViewedLicenseId] = useState(null);
    return (
        <List>
            {Object.keys(licenses).map(licenseId => {
                return (
                    <ListItem button key={licenseId}>
                        <ListItemText
                            primary={licenses[licenseId].friendlyName}
                            secondary={
                                licenses[licenseId].active ? (
                                    <span style={{ color: "green" }}>
                                        active
                                    </span>
                                ) : (
                                    <span style={{ color: "red" }}>
                                        inactive
                                    </span>
                                )
                            }
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title="Change Name">
                                <IconButton
                                    onClick={() => {
                                        setViewedLicenseId(licenseId);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            {licenses[licenseId].active ? null : (
                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => {
                                            insyncHttps.billing
                                                .removeLicenseFromOffice({
                                                    officeId,
                                                    licenseId
                                                })
                                                .then(result => {
                                                    subscriptions.getSubs();
                                                })
                                                .catch(error => {
                                                    console.error(error);
                                                });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
            <Modal
                keepMounted={false}
                style={{ display: "flex" }}
                open={viewedLicenseId !== null}
                onClose={() => {
                    setViewedLicenseId(null);
                }}
            >
                <RenameLicense
                    handleClose={() => {
                        setViewedLicenseId(null);
                    }}
                    licenseId={viewedLicenseId}
                    license={licenses[viewedLicenseId]}
                />
            </Modal>
        </List>
    );
}

const RenameLicense = React.forwardRef((props, ref) => {
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const license = props.license;
    const licenseId = props.licenseId;
    const [name, setName] = useState(license.friendlyName);
    const [loading, setLoading] = useState(false);
    const handleClose = props.handleClose;
    return (
        <Card style={{ margin: "auto" }} ref={ref}>
            <CardHeader title="Rename License"> </CardHeader>
            <CardContent>
                <TextField
                    type="text"
                    label="name"
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                    }}
                />
            </CardContent>
            <CardActions>
                <Button
                    disabled={loading}
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.billing
                            .renameLicense({ officeId, licenseId, name })
                            .then(result => {
                                setLoading(false);
                                handleClose();
                            })
                            .catch(err => {
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Change
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardActions>
        </Card>
    );
});
