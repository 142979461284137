import React, { useContext, useState } from "react";
import { PagingContext } from "../PagingContext";
import {
    ListItemText,
    ListItem,
    List,
    CircularProgress,
    Grid,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    Card
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";
import EditRoom from "./EditRoom";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const { room, roomId, moveRoomUp, moveRoomDown } = props;
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { getPaging } = useContext(PagingContext);
    const [editRoomOpen, setEditRoomOpen] = useState(false);

    return (
        <Grid item xs={12} lg={5}>
            <List>
                <Card style={{ border: "1px solid black" }}>
                    <ListItemText primary={room.name} />
                    <IconButton
                        size="small"
                        onClick={() => {
                            setEditRoomOpen(true);
                        }}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setLoading(true);
                            insyncHttps.paging
                                .removeRoomFromOffice({ officeId, roomId })
                                .then(result => {
                                    setLoading(false);
                                    getPaging();
                                })
                                .catch(err => {
                                    setLoading(false);
                                    error.setMessage(err.message);
                                });
                        }}
                        size="small"
                        style={{
                            backgroundColor: "white"
                        }}
                    >
                        <DeleteIcon />
                        {loading && <CircularProgress size={10} />}
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => {
                            moveRoomUp(roomId);
                        }}
                    >
                        <ArrowUpwardIcon></ArrowUpwardIcon>
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => {
                            moveRoomDown(roomId);
                        }}
                    >
                        <ArrowDownwardIcon></ArrowDownwardIcon>
                    </IconButton>
                </Card>
            </List>
            <Dialog
                open={editRoomOpen}
                onClose={() => {
                    setEditRoomOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <EditRoom
                            roomId={roomId}
                            handleClose={() => {
                                setEditRoomOpen(false);
                            }}
                            room={room}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
