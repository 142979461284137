import React, { useState, useContext } from "react";
import firebase from "../../../../firebase";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Dialog,
    List,
    ListItem,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    ListItemSecondaryAction,
    IconButton,
    ListItemText,
    Tooltip,
    CircularProgress,
    Collapse,
    Modal
} from "@material-ui/core";

import { BillingContext } from "../BillingContext";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EditIcon from "@material-ui/icons/Edit";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const { customer } = useContext(BillingContext);
    const [jsonOpen, setJsonOpen] = useState(false);
    const [changeEmailOpen, setChangeEmailOpen] = useState(false);

    let content;
    if (customer.error) {
        content = (
            <CardContent>
                <ErrorStateDisplay
                    title={"Error Getting Customer"}
                    message={customer.error}
                    retry={customer.getCustomer}
                />
            </CardContent>
        );
    } else {
        content = (
            <React.Fragment>
                <CardContent>
                    {customer.loading ? (
                        <CircularProgress size={30} />
                    ) : (
                        <List>
                            {customer.customer && customer.customer.customer && (
                                <ListItem button>
                                    <ListItemText
                                        primary={`Billing Email: ${customer.customer.customer.email}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            onClick={() => {
                                                setChangeEmailOpen(true);
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    )}
                    <Modal
                        style={{ display: "flex" }}
                        open={changeEmailOpen}
                        onClose={() => {
                            setChangeEmailOpen(false);
                        }}
                    >
                        <ChangeEmail
                            handleClose={() => {
                                setChangeEmailOpen(false);
                            }}
                        />
                    </Modal>
                </CardContent>
                <CardContent>
                    <IconButton
                        onClick={() => {
                            setJsonOpen(!jsonOpen);
                        }}
                    >
                        {jsonOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                    <Collapse in={jsonOpen} timeout="auto">
                        <pre>{JSON.stringify(customer.customer, null, 4)}</pre>
                    </Collapse>
                </CardContent>
            </React.Fragment>
        );
    }

    return (
        <Card>
            <CardHeader title="CUSTOMER" />
            {content}
        </Card>
    );
}

const ChangeEmail = React.forwardRef((props, ref) => {
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { customer } = useContext(BillingContext);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const handleClose = props.handleClose;

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Change Billing Email" />
            <CardContent>
                <TextField
                    label="email"
                    type="text"
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                    }}
                />
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    disabled={loading}
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.billing
                            .changeCustomerEmail({ officeId, email })
                            .then(result => {
                                setLoading(false);
                                handleClose();
                                customer.getCustomer();
                            })
                            .catch(err => {
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    SUBMIT
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardActions>
        </Card>
    );
});
