import React, { useState, useContext } from "react";
import {
    CardContent,
    Card,
    CardHeader,
    Grid,
    TextField,
    RadioGroup,
    FormControlLabel,
    Button,
    Radio,
    Menu,
    CircularProgress
} from "@material-ui/core";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";

import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { PatientTrackingContext } from "../PatientTrackingContext";

export default React.forwardRef((props, ref) => {
    const roomId = props.roomId;
    const [name, setName] = useState(props.room.name);
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { getPatientTracking } = useContext(PatientTrackingContext);
    const handleClose = props.handleClose;
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Edit Room" />
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    align="center"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <Grid container justify="center" align="center">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="name"
                                    value={name}
                                    onChange={e => {
                                        setName(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLoading(true);
                                insyncHttps.patientTracking
                                    .updateRoom({ officeId, roomId, name })
                                    .then(result => {
                                        setLoading(false);
                                        getPatientTracking();
                                        handleClose();
                                    })
                                    .catch(err => {
                                        console.error(err);
                                        setLoading(false);
                                        error.setMessage(err.message);
                                    });
                            }}
                        >
                            Submit
                        </Button>
                        {loading && <CircularProgress size={10} />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
