import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Checkbox,
    Grid,
    FormControlLabel,
    Button,
    CircularProgress
} from "@material-ui/core";
import { MessagingContext } from "./MessagingContext";
import { OfficeContext } from "../OfficeContext";
import { appContext } from "../../../contexts/app";
import firebase from "../../../firebase";
import insyncHttps from "../../../insyncHttps/insyncHttps";

export default React.forwardRef((props, ref) => {
    const user = props.user;
    const userId = props.userId;
    const [name, setName] = useState(user.name);
    const [isPw, setIsPw] = useState(user.passwordProtected);
    const [password, setPassword] = useState(user.password);
    const { getMessaging } = useContext(MessagingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const [loading, setLoading] = useState(false);
    const handleClose = props.handleClose;

    const editUser = () => {
        setLoading(true);
        insyncHttps.messaging
            .updateUser({
                officeId,
                name,
                passwordProtected: isPw,
                password,
                userId
            })
            .then(result => {
                setLoading(false);
                getMessaging();
                handleClose();
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
                error.setMessage(err.message);
            });
    };

    return (
        <Card ref={ref}>
            <CardHeader title="Edit Messaging User" />
            <CardContent>
                <Grid container align="center" justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            label="name"
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            label="Password Protected"
                            control={
                                <Checkbox
                                    checked={isPw}
                                    onChange={e => {
                                        setIsPw(e.target.checked);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={!isPw}
                            type="password"
                            label="password"
                            value={password}
                            onChange={e => {
                                setPassword(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={loading}
                            variant="contained"
                            onClick={() => {
                                editUser();
                            }}
                        >
                            SUBMIT
                        </Button>
                        {loading && <CircularProgress size={10} />}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
