import React, { useContext, useEffect, useState } from "react";
import VisitReport1Context from "../../VisitReport1Context";

import { Card, CardContent } from "@material-ui/core";

export default function(props) {
    const reportCtx = useContext(VisitReport1Context);

    useEffect(() => {}, [reportCtx.visits]);

    const averageVisitTime = (() => {
        const completedVisits = reportCtx.visits.filter(visit => {
            return visit.completedTime && visit.completedTime > 0;
        });

        const totalTime = completedVisits.reduce((acc, curr) => {
            return (acc += curr.completedTime - curr.arrivalTime);
        }, 0);

        return Math.floor(totalTime / completedVisits.length / 60000) || 0;
    })();

    return (
        <Card>
            <CardContent>
                <h2 style={{ textAlign: "center" }}>Average Visit Time</h2>
                <h1 style={{ textAlign: "center" }}>
                    {averageVisitTime} minutes
                </h1>
            </CardContent>
        </Card>
    );
}
