import React, { useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
    usePatientTracking,
    PatientTrackingContext
} from "./PatientTrackingContext";
import { OfficeContext } from "../OfficeContext";
import ErrorStateDisplay from "../../ErrorStateDisplay/ErrorStateDisplay";
import Rooms from "./Rooms/Rooms";
import Stages from "./Stages/Stages";
import VisitDisplaySettings from "./VisitDisplaySettings/VisitDisplaySettings";
import VisitSettings from "./VisitSettings/VisitSettings";
import ColumnLayoutEditor from "./ColumnLayoutEditor/ColumnLayoutEditor";

export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const patientTracking = usePatientTracking(officeId);
    useEffect(() => {
        patientTracking.getPatientTracking();
    }, []);

    if (patientTracking.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting PatientTracking"
                message={patientTracking.error}
                retry={patientTracking.getPatientTracking}
            />
        );
    }
    return (
        <PatientTrackingContext.Provider value={patientTracking}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Rooms />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Stages />
                </Grid>
                {/* <Grid item xs={12} lg={6}>
                    <VisitDisplaySettings></VisitDisplaySettings>
                </Grid> */}
                <Grid item xs={12} lg={6}>
                    <VisitSettings></VisitSettings>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ColumnLayoutEditor></ColumnLayoutEditor>
                </Grid>
            </Grid>
        </PatientTrackingContext.Provider>
    );
}
