import React, { useContext, useEffect, useState } from "react";
import VisitReport1Context from "../../VisitReport1Context";
import { VictoryBar, VictoryLabel } from "victory";
import { Bar } from "victory-bar";
import { Card, CardContent } from "@material-ui/core";

export default function(props) {
    const reportCtx = useContext(VisitReport1Context);
    const [slices, setSlices] = useState([{ totalTime: 1 }]);

    useEffect(() => {
        const mySlices = createSlices();
        setSlices(mySlices.length > 0 ? mySlices : [{ totalTime: 0 }]);
    }, [reportCtx.visits]);

    function getStageById(id) {
        return reportCtx.stages.find(stage => {
            return stage.id === id;
        });
    }

    function createSlices() {
        //get cumulative total of time spent in each stage
        const combinedStageTimes = {};
        reportCtx.visits
            .filter(visit => {
                return visit.completedTime && visit.completedTime > 0;
            })
            .forEach(visit => {
                Object.keys(visit.stageTimes).forEach(stageId => {
                    if (!combinedStageTimes[stageId]) {
                        combinedStageTimes[stageId] = {
                            id: stageId,
                            totalTime: visit.stageTimes[stageId]
                        };
                    } else {
                        combinedStageTimes[stageId].totalTime +=
                            visit.stageTimes[stageId];
                    }
                });
            });

        const sliceData = Object.keys(combinedStageTimes).map(
            combinedStageTimesId => {
                const element = combinedStageTimes[combinedStageTimesId];
                const stage = getStageById(element.id);
                element.name = stage && stage.name ? stage.name : element.id;
                element.backgroundColor =
                    stage && stage.backgroundColor
                        ? stage.backgroundColor
                        : "grey";
                element.textColor =
                    stage && stage.textColor ? stage.textColor : "black";
                element.value = element.totalTime;
                return element;
            }
        );

        return sliceData;
    }

    return (
        <Card>
            <CardContent>
                <h2 style={{ textAlign: "center" }}>
                    Average Time in Stage per Visit
                </h2>
                <div style={{ maxWidth: "20vw", margin: "0 auto" }}>
                    <VictoryBar
                        barRatio={0.5}
                        barWidth={50}
                        style={{
                            data: {
                                fill: data => {
                                    return data.datum.backgroundColor;
                                }
                            },
                            labels: { fontSize: 20 },
                            parent: { overflow: "visible" }
                        }}
                        events={[
                            {
                                target: "data",
                                eventHandlers: {
                                    onClick: (evt, props) => {
                                        const { datum } = props;
                                        alert(datum.name);
                                    }
                                }
                            }
                        ]}
                        name="Average Time Per Stage"
                        height={400}
                        width={400}
                        labels={({ datum }) => {
                            return `${datum.name}
                            ${Math.floor(datum.totalTime / 60000)} Minutes`;
                        }}
                        labelComponent={
                            <VictoryLabel renderInPortal></VictoryLabel>
                        }
                        data={slices.map((slice, index) => {
                            return {
                                ...slice,
                                x: index,
                                y: slice.totalTime
                            };
                        })}
                    ></VictoryBar>
                </div>
            </CardContent>
        </Card>
    );
}
