import React, { useContext, forwardRef } from "react";

import VisitReport1Context from "../../VisitReport1Context";
import moment from "moment";
import { Card, CardContent, Grid } from "@material-ui/core";
import StagePie from "./StagePie";
import TotalVisits from "./TotalVisits";
import AverageVisitTime from "./AverageVisitTime";
import PatientDensity from "./PatientDensity";

import LongestVisit from "./LongestVisit";
import ShortestVisit from "./ShortestVisit";
import AverageTimePerStageBar from "./AverageTimePerStageBar";

export default forwardRef((props, ref) => {
    const reportCtx = useContext(VisitReport1Context);
    const reportTime = moment(reportCtx.reportTime).format("LT YYYY/MM/DD ");
    const endDate = moment(reportCtx.reportEndDate).format("YYYY/MM/DD");
    const startDate = moment(reportCtx.reportStartDate).format("YYYY/MM/DD");

    return (
        <Grid container spacing={1} ref={ref}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <div>{`Generated at: ${reportTime}`}</div>
                        <div>{`Period: ${startDate} - ${endDate}`}</div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <TotalVisits></TotalVisits>
            </Grid>
            {reportCtx.visits && reportCtx.visits.length > 0 && (
                <React.Fragment>
                    <Grid item xs={6}>
                        <AverageVisitTime></AverageVisitTime>
                    </Grid>
                    <Grid item xs={6}>
                        <LongestVisit></LongestVisit>
                    </Grid>

                    <Grid item xs={6}>
                        <ShortestVisit></ShortestVisit>
                    </Grid>

                    <Grid item xs={6}>
                        <StagePie></StagePie>
                    </Grid>
                    <Grid item xs={6}>
                        <AverageTimePerStageBar></AverageTimePerStageBar>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <PatientDensity></PatientDensity>
                    </Grid> */}
                </React.Fragment>
            )}
        </Grid>
    );
});
