import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    ListItem,
    ListItemText,
    List,
    Tooltip,
    Modal,
    Menu,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    CardActions,
    Container,
    CircularProgress
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import firebase from "../../../firebase";
import { useUsers, UsersContext } from "./UsersContext";
import { appContext } from "../../../contexts/app";
import { OfficeContext } from "../OfficeContext";
import insyncHttps from "../../../insyncHttps/insyncHttps";
export default React.forwardRef((props, ref) => {
    const user = props.user;
    const [addRoleOpen, setAddRoleOpen] = useState(false);
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Roles" subheader={user.email} />
            <CardContent>
                <List dense={true}>
                    {user.roles.map(role => {
                        return <Role key={role} user={user} role={role} />;
                    })}
                </List>
                <Tooltip title="Add Role">
                    <IconButton
                        onClick={e => {
                            setAddRoleOpen(true);
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <Modal
                    style={{ display: "flex" }}
                    open={addRoleOpen}
                    onClose={() => {
                        setAddRoleOpen(false);
                    }}
                >
                    <AddRole user={user} />
                </Modal>
            </CardContent>
        </Card>
    );
});

function Role(props) {
    const { officeId } = useContext(OfficeContext);
    const users = useContext(UsersContext);
    const user = props.user;
    const role = props.role;
    const { error } = useContext(appContext);
    const [loading, setLoading] = useState(false);
    return (
        <ListItem button divider>
            <ListItemText primary={role} />
            <IconButton
                onClick={() => {
                    setLoading(true);
                    insyncHttps.insyncUser
                        .removeOfficeRoleFromUser({
                            officeId: officeId,
                            email: user.email,
                            roleName: role
                        })
                        .then(result => {
                            users.getUsers();
                            setLoading(false);
                        })
                        .catch(err => {
                            error.setMessage(err.message);
                            setLoading(false);
                            console.error(err);
                        });
                }}
            >
                {loading ? <CircularProgress size={10} /> : <DeleteIcon />}
            </IconButton>
        </ListItem>
    );
}

const AddRole = React.forwardRef((props, ref) => {
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const users = useContext(UsersContext);
    const user = props.user;
    const [selectedRole, setSelectedRole] = useState("");
    const [loading, setLoading] = useState(false);
    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title={`Add Role To ${user.email}`} />
            <CardContent style={{ display: "flex", justifyContent: "center" }}>
                <FormControl>
                    <InputLabel htmlFor="add-role">Role</InputLabel>
                    <Select
                        inputProps={{ name: "Role", id: "add-role" }}
                        value={selectedRole}
                        onChange={e => {
                            setSelectedRole(e.target.value);
                        }}
                    >
                        <MenuItem value={"billingAdmin"}>billingAdmin</MenuItem>
                        <MenuItem value={"officeAdmin"}>officeAdmin</MenuItem>
                        <MenuItem value={"officeUser"}>officeUser</MenuItem>
                    </Select>
                </FormControl>
            </CardContent>
            <CardActions style={{ justifyContent: "center" }}>
                <Button
                    variant="contained"
                    disabled={!selectedRole}
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.insyncUser
                            .addOfficeRoleToUser({
                                officeId: officeId,
                                email: user.email,
                                roleName: selectedRole
                            })
                            .then(result => {
                                users.getUsers();
                                setLoading(false);
                            })
                            .catch(err => {
                                error.setMessage(err.message);
                                setLoading(false);
                                console.error(err);
                            });
                        console.log("this should add a role");
                    }}
                >
                    ADD
                </Button>
                {loading ? <CircularProgress size={10} /> : null}
            </CardActions>
        </Card>
    );
});
