import React, { useContext } from "react";

import VisitReport1Context from "../../VisitReport1Context";

import { Card, CardContent, Grid } from "@material-ui/core";
import ReportContent from "./ReportContent";

export default function(props) {
    const reportCtx = useContext(VisitReport1Context);

    const { printRef } = reportCtx;

    return (
        <Card
            style={{
                height: "800px",
                overflowY: "scroll",
                backgroundColor: "rgb(226, 226, 226)"
            }}
        >
            <CardContent ref={printRef}>
                <ReportContent></ReportContent>
            </CardContent>
        </Card>
    );
}
