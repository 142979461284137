import React from "react";
import {
    CardContent,
    Card,
    CardActions,
    Button,
    Grid
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export default function(props) {
    const title = props.title;
    const message = props.message;
    const retry = props.retry;
    return (
        <Card>
            <CardContent>
                <Grid container align="center" justify="center">
                    <Grid item xs={12}>
                        {title}
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorOutlineIcon />
                    </Grid>
                    <Grid item xs={12}>
                        {message}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={retry}>
                            retry
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
