import React, { useContext, useState, useEffect } from "react";
import { authContext } from "../../contexts/auth";
import {
    List,
    ListItem,
    Grow,
    Grid,
    CardContent,
    Card,
    CardHeader,
    Paper,
    Button,
    TextField,
    Dialog,
    CardActions,
    CardActionArea,
    CircularProgress,
    ListItemText
} from "@material-ui/core";
import firebase from "../../firebase";
import { mainRouterHistory } from "../../routerHistories";
import ErrorStateDisplay from "../ErrorStateDisplay/ErrorStateDisplay";
import { DashboardContext, useDashboard } from "./DashboardContext";
import OfficeList from "./OfficeList";
import insyncHttps from "../../insyncHttps/insyncHttps";

export default function(props) {
    const { user } = useContext(authContext);
    const [createOfficeDialogOpen, setCreateOfficeDialogOpen] = useState(false);
    const { officesMeta, getOfficeList, error, loading } = useDashboard();

    useEffect(() => {
        getOfficeList();
    }, []);

    if (!user) {
        return <div>Loading</div>;
    }

    return (
        <DashboardContext.Provider
            value={{ officesMeta, getOfficeList, error, loading }}
        >
            <Grid
                container
                style={{ width: "100%", margin: "0 auto" }}
                container
                spacing={8}
                justify="space-around"
                align="center"
            >
                <Grid item xs={12}>
                    Dashboard for {user.email}
                </Grid>
                <Grid item xs={12}>
                    {error ? (
                        <ErrorStateDisplay
                            title="Error Getting Offices"
                            message={error}
                            retry={getOfficeList}
                        />
                    ) : (
                        <OfficeList />
                    )}
                </Grid>
            </Grid>
        </DashboardContext.Provider>
    );
}
