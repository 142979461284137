import React, { useContext, useState, useEffect } from "react";
import { PagingContext } from "../PagingContext";
import {
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    CardActions,
    Dialog,
    Button
} from "@material-ui/core";
import AddPersons from "./AddPersons";
import AddIcon from "@material-ui/icons/Add";
import PersonListItem from "./PersonListItem";
import { reorderPersons } from "../../../../insyncHttps/paging/paging";
import { OfficeContext } from "../../OfficeContext";

export default function(props) {
    const { persons, loading, getPaging } = useContext(PagingContext);
    const { officeId } = useContext(OfficeContext);
    const [addPersonsOpen, setAddPersonsOpen] = useState(false);
    const [orderedPersonIds, setOrderedPersonIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    function movePersonUp(personId) {
        const currentIndex = orderedPersonIds.indexOf(personId);
        if (currentIndex > 0) {
            orderedPersonIds.splice(currentIndex, 1);
            orderedPersonIds.splice(currentIndex - 1, 0, personId);
            setOrderModified(true);
            setOrderedPersonIds([...orderedPersonIds]);
        }
    }

    function movePersonDown(personId) {
        const currentIndex = orderedPersonIds.indexOf(personId);
        if (currentIndex < orderedPersonIds.length - 1) {
            orderedPersonIds.splice(currentIndex, 1);
            orderedPersonIds.splice(currentIndex + 1, 0, personId);
            setOrderedPersonIds([...orderedPersonIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        setOrderModified(false);
        setOrderedPersonIds(getSortedPersonIds(persons));
    }, [persons]);

    return (
        <Card>
            <CardHeader title="Persons" />
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    justify="center"
                    style={{ height: 300, overflowY: "scroll" }}
                >
                    {loading ? (
                        <CircularProgress size={20} />
                    ) : (
                        orderedPersonIds.map(personId => {
                            const person = persons[personId];
                            if (!persons[personId]) {
                                return null;
                            }
                            return (
                                <PersonListItem
                                    key={personId}
                                    {...{
                                        person,
                                        personId,
                                        movePersonDown,
                                        movePersonUp
                                    }}
                                />
                            );
                        })
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <IconButton
                    onClick={() => {
                        setAddPersonsOpen(true);
                    }}
                >
                    <AddIcon />
                </IconButton>
                {orderModified && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSaveOrderLoading(true);
                            reorderPersons({
                                officeId,
                                personIds: orderedPersonIds
                            })
                                .then(result => {
                                    setSaveOrderLoading(false);
                                    getPaging();
                                })
                                .catch(err => {
                                    setSaveOrderLoading(false);
                                    getPaging();
                                });
                        }}
                    >
                        save order
                        {saveOrderLoading && (
                            <CircularProgress size={10}></CircularProgress>
                        )}
                    </Button>
                )}
            </CardActions>
            <Dialog
                open={addPersonsOpen}
                onClose={() => {
                    setAddPersonsOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <AddPersons
                            handleClose={() => {
                                setAddPersonsOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Card>
    );
}

function getSortedPersonIds(persons) {
    return Object.keys(persons)
        .filter(personId => {
            return Object.keys(persons).includes(personId);
        })
        .sort((a, b) => {
            if (persons[a].name > persons[b].name) {
                return 1;
            }
            if (persons[a].name < persons[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const personA = { ...persons[a] };
            const personB = { ...persons[b] };
            personA.listPosition = personA.listPosition
                ? personA.listPosition
                : 0;
            personB.listPosition = personB.listPosition
                ? personB.listPosition
                : 0;

            return personA.listPosition - personB.listPosition;
        });
}
