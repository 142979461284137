import React, { useContext, useState } from "react";

import {
    ListItemText,
    ListItem,
    List,
    CircularProgress,
    Grid,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    Card,
    Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

import { appContext } from "../../../../contexts/app";
import EditRoom from "./EditRoom";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { OfficeContext } from "../../OfficeContext";

export default function(props) {
    const { room, roomId, moveRoomUp, moveRoomDown } = props;
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { getPatientTracking, defaults } = useContext(PatientTrackingContext);
    
    const [editRoomOpen, setEditRoomOpen] = useState(false);
    const isDefault = defaults && defaults.roomId? defaults.roomId === roomId : false;

    return (
        <Grid item xs={12} lg={5}>
            <List>
                <Card style={{ border: "1px solid black" }}>
                    <ListItemText primary={room.name} />

                    <IconButton
                        size="small"
                        onClick={() => {
                            setEditRoomOpen(true);
                        }}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>

                    {!isDefault && (
                        <Tooltip title={"set as default"}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setLoading(true);
                                    insyncHttps.patientTracking
                                        .setDefaultRoom({
                                            officeId,
                                            roomId
                                        })
                                        .then(result => {
                                            setLoading(false);
                                            getPatientTracking();
                                        })
                                        .catch(err => {
                                            setLoading(false);
                                            error.setMessage(err.message);
                                        });
                                }}
                            >
                                <BookmarkBorderIcon></BookmarkBorderIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {isDefault && (
                        <Tooltip title={"default"}>
                            <IconButton size="small">
                                <BookmarkIcon></BookmarkIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                    <IconButton
                        onClick={() => {
                            setLoading(true);
                            insyncHttps.patientTracking
                                .removeRoomFromOffice({ officeId, roomId })
                                .then(result => {
                                    setLoading(false);
                                    getPatientTracking();
                                })
                                .catch(err => {
                                    setLoading(false);
                                    error.setMessage(err.message);
                                });
                        }}
                        size="small"
                        style={{
                            backgroundColor: "white"
                        }}
                    >
                        <DeleteIcon />
                        {loading && <CircularProgress size={10} />}
                    </IconButton>
                </Card>
            </List>
            <Dialog
                open={editRoomOpen}
                onClose={() => {
                    setEditRoomOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <EditRoom
                            roomId={roomId}
                            handleClose={() => {
                                setEditRoomOpen(false);
                            }}
                            room={room}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
