import React, { useContext } from "react";
import ColumnListContext from "./ColumnLayoutContext";
import useColumnLayout from "./useColumnLayout";
import ColumnList from "./ColumnList";
import {
    Card,
    CardContent,
    Button,
    CardHeader,
    CircularProgress
} from "@material-ui/core";
import { PatientTrackingContext } from "../PatientTrackingContext";

export default function(props) {
    const columnLayoutCtx = useColumnLayout();
    const patientTracking = useContext(PatientTrackingContext);
    return (
        <ColumnListContext.Provider value={columnLayoutCtx}>
            <Card>
                <CardHeader title={"Column Layout"}></CardHeader>
                <CardContent>
                    {patientTracking.loading ? (
                        <CircularProgress></CircularProgress>
                    ) : (
                        <React.Fragment>
                            <ColumnList></ColumnList>
                            {columnLayoutCtx.localState.modified && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                        columnLayoutCtx.updateColumnLayout();
                                    }}
                                >
                                    update
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
        </ColumnListContext.Provider>
    );
}
