import React, { createContext, useState } from "react";
import insyncHttps from "../../insyncHttps/insyncHttps";

export const DashboardContext = createContext({
    officesMeta: {},
    getOfficeList: () => {}
});

export const useDashboard = () => {
    const [officesMeta, setOfficesMeta] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getOfficeList = () => {
        setError(null);
        setLoading(true);
        insyncHttps.office
            .getOfficeList()
            .then(result => {
                setLoading(false);
                setOfficesMeta(result.data);
            })
            .catch(err => {
                console.error(err);
                setError(err.message);
                setLoading(false);
            });
    };

    return { officesMeta, getOfficeList, error, loading };
};
