import React, { useContext, useEffect, useState } from "react";
import VisitReport1Context from "../../VisitReport1Context";

import { Card, CardContent } from "@material-ui/core";

export default function(props) {
    const reportCtx = useContext(VisitReport1Context);

    const totalVisits = reportCtx.visits.filter(visit => {
        return visit.completedTime && visit.completedTime > 0;
    }).length;

    return (
        <Card>
            <CardContent>
                <h2 style={{ textAlign: "center" }}>Total Visits</h2>
                <h1 style={{ textAlign: "center" }}>
                    {totalVisits}
                </h1>
            </CardContent>
        </Card>
    );
}
