import React, { useState, useContext } from "react";
import {
    CardContent,
    Card,
    TextField,
    Input,
    CardActions,
    Button,
    Grid,
    CardHeader
} from "@material-ui/core";
import firebase from "../../../../firebase";
import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const { paymentSources } = useContext(BillingContext);
    const handleClose = props.handleClose;
    const { error } = useContext(appContext);
    const [cardNumber, setCardNumber] = useState("");
    const [cvv, setCvv] = useState("");
    const [expiry_month, setExpiry_month] = useState("");
    const [expiry_year, setExpiry_year] = useState("");

    return (
        <Card>
            <CardHeader title="Add Payment Source" />
            <CardContent>
                <Grid container align="center" spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            value={cardNumber}
                            onChange={e => {
                                setCardNumber(e.target.value);
                            }}
                            label="Card Number"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={cvv}
                            onChange={e => {
                                setCvv(e.target.value);
                            }}
                            label="CVV"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={expiry_month}
                            onChange={e => {
                                setExpiry_month(e.target.value);
                            }}
                            type="number"
                            label="expiry month"
                            placeholder="01"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={expiry_year}
                            onChange={e => {
                                setExpiry_year(e.target.value);
                            }}
                            type="number"
                            label="expiry year"
                            placeholder="1999"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                insyncHttps.billing
                                    .createPaymentSource({
                                        officeId,
                                        cardNumber,
                                        cvv,
                                        expiry_month,
                                        expiry_year
                                    })
                                    .then(result => {
                                        console.log(result);
                                        paymentSources.getSources();
                                        handleClose();
                                    })
                                    .catch(err => {
                                        error.setMessage(err.message);
                                        console.error(error);
                                    });
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
