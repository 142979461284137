import React, { useContext } from "react";
import SingleVisit from "./SingleVisit/SingleVisit";
import VisitReport1Context from "../../VisitReport1Context";
import { CardContent, Card } from "@material-ui/core";

export default function(props) {
    const reportCtx = useContext(VisitReport1Context);
    const { visits } = reportCtx;

    function getShortestVisit() {
        const completedVisits = visits.filter(visit => {
            return visit.completedTime && visit.completedTime > 0;
        });

        const sortedVisits = completedVisits.sort((a, b) => {
            const aduration = a.completedTime - a.arrivalTime;
            const bduration = b.completedTime - b.arrivalTime;
            return aduration - bduration;
        });

        if (sortedVisits.length > 0) {
            return sortedVisits[0];
        } else {
            return null;
        }
    }

    const visit = getShortestVisit();
    const visitTime =
        visit && visit.completedTime && visit.arrivalTime
            ? Math.floor((visit.completedTime - visit.arrivalTime) / 60000)
            : 0;

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <h2 style={{ textAlign: "center" }}>Shortest Visit</h2>
                    <h1 style={{ textAlign: "center" }}>{visitTime} Minutes</h1>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}
