import React, { useContext, useState, useEffect } from "react";
import { PagingContext } from "../PagingContext";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    CardActions,
    Dialog,
    Button
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import AddRooms from "./AddRooms";
import RoomListItem from "./RoomListItem";
import { OfficeContext } from "../../OfficeContext";
import { reorderRooms } from "../../../../insyncHttps/paging/paging";

export default function(props) {
    const { rooms, loading, getPaging } = useContext(PagingContext);
    const { officeId } = useContext(OfficeContext);

    const [addRoomsOpen, setAddRoomsOpen] = useState(false);
    const [orderedRoomIds, setOrderedRoomIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    function moveRoomUp(roomId) {
        const currentIndex = orderedRoomIds.indexOf(roomId);
        if (currentIndex > 0) {
            orderedRoomIds.splice(currentIndex, 1);
            orderedRoomIds.splice(currentIndex - 1, 0, roomId);
            setOrderModified(true);
            setOrderedRoomIds([...orderedRoomIds]);
        }
    }

    function moveRoomDown(roomId) {
        const currentIndex = orderedRoomIds.indexOf(roomId);
        if (currentIndex < orderedRoomIds.length - 1) {
            orderedRoomIds.splice(currentIndex, 1);
            orderedRoomIds.splice(currentIndex + 1, 0, roomId);
            setOrderedRoomIds([...orderedRoomIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        setOrderModified(false);
        setOrderedRoomIds(getSortedRoomIds(rooms));
    }, [rooms]);

    return (
        <Card>
            <CardHeader title="Rooms" />
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    justify="center"
                    style={{ height: 300, overflowY: "scroll" }}
                >
                    {loading ? (
                        <CircularProgress size={20} />
                    ) : (
                        orderedRoomIds.map(roomId => {
                            const room = rooms[roomId];
                            if (!rooms[roomId]) {
                                return null;
                            }
                            return (
                                <RoomListItem
                                    key={roomId}
                                    room={room}
                                    roomId={roomId}
                                    moveRoomUp={moveRoomUp}
                                    moveRoomDown={moveRoomDown}
                                />
                            );
                        })
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <IconButton
                    onClick={() => {
                        setAddRoomsOpen(true);
                    }}
                >
                    <AddIcon />
                </IconButton>
                {orderModified && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSaveOrderLoading(true);
                            reorderRooms({
                                officeId,
                                roomIds: orderedRoomIds
                            })
                                .then(result => {
                                    setSaveOrderLoading(false);
                                    getPaging();
                                })
                                .catch(err => {
                                    setSaveOrderLoading(false);
                                    getPaging();
                                });
                        }}
                    >
                        save order
                        {saveOrderLoading && (
                            <CircularProgress size={10}></CircularProgress>
                        )}
                    </Button>
                )}
            </CardActions>
            <Dialog
                open={addRoomsOpen}
                onClose={() => {
                    setAddRoomsOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <AddRooms
                            handleClose={() => {
                                setAddRoomsOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Card>
    );
}

function getSortedRoomIds(rooms) {
    return Object.keys(rooms)
        .filter(roomId => {
            return Object.keys(rooms).includes(roomId);
        })
        .sort((a, b) => {
            if (rooms[a].name > rooms[b].name) {
                return 1;
            }
            if (rooms[a].name < rooms[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const roomA = { ...rooms[a] };
            const roomB = { ...rooms[b] };
            roomA.listPosition = roomA.listPosition ? roomA.listPosition : 0;
            roomB.listPosition = roomB.listPosition ? roomB.listPosition : 0;

            return roomA.listPosition - roomB.listPosition;
        });
}
