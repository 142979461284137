import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    Grid,
    IconButton,
    Menu,
    RadioGroup,
    FormControlLabel,
    Radio,
    MenuItem,
    ListItemText,
    ListItemSecondaryAction,
    Switch
} from "@material-ui/core";

import { SketchPicker } from "react-color";

import { playSoundEffect } from "../../../../audio/audio";
import soundEffects from "../../../../audio/soundEffects";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

export default function(props) {
    const person = props.person;

    const handleChange = props.handleChange;
    const [bgColorAnchorEl, setBgColorAnchorEl] = useState(null);
    const [soundEffectColorAnchorEl, setSoundEffectAnchorEl] = useState(null);

    return (
        <Card style={{ width: "100%" }}>
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    align="center"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={5}>
                        <Grid container justify="center" align="center">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="name"
                                    value={person.name}
                                    onChange={e => {
                                        person.name = e.target.value;
                                        handleChange(person);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <RadioGroup
                                    value={person.textColor}
                                    onChange={e => {
                                        person.textColor = e.target.value;
                                        handleChange(person);
                                    }}
                                >
                                    <FormControlLabel
                                        value={"#000000"}
                                        control={<Radio />}
                                        label="Black Text"
                                    />
                                    <FormControlLabel
                                        value={"#FFFFFF"}
                                        control={<Radio />}
                                        label="White Text"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={e => {
                                        setBgColorAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={6}>
                                            BG color:
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            style={{
                                                backgroundColor: person.bgColor
                                            }}
                                        >
                                            {person.bgColor}
                                        </Grid>
                                    </Grid>
                                </Button>
                                <Menu
                                    anchorEl={bgColorAnchorEl}
                                    open={Boolean(bgColorAnchorEl)}
                                    onClose={() => {
                                        setBgColorAnchorEl(null);
                                    }}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                >
                                    <Card>
                                        <CardHeader title="BG Color" />
                                        <SketchPicker
                                            color={person.bgColor}
                                            onChangeComplete={color => {
                                                person.bgColor = color.hex;
                                                handleChange(person);
                                            }}
                                        />
                                    </Card>
                                </Menu>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={e => {
                                        setSoundEffectAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={6}>
                                            Sound:
                                        </Grid>
                                        <Grid item xs={6}>
                                            {person.soundId}
                                        </Grid>
                                    </Grid>
                                </Button>
                                <Menu
                                    anchorEl={soundEffectColorAnchorEl}
                                    open={Boolean(soundEffectColorAnchorEl)}
                                    onClose={() => {
                                        setSoundEffectAnchorEl(null);
                                    }}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                >
                                    {Object.keys(soundEffects).map(soundId => {
                                        return (
                                            <MenuItem
                                                selected={
                                                    soundId &&
                                                    soundId === person.soundId
                                                }
                                                key={soundId}
                                            >
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        onClick={() => {
                                                            person.soundId = soundId;
                                                            handleChange(
                                                                person
                                                            );
                                                            setSoundEffectAnchorEl(
                                                                null
                                                            );
                                                        }}
                                                    >
                                                        {soundId}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                playSoundEffect(
                                                                    soundEffects[
                                                                        soundId
                                                                    ]
                                                                );
                                                            }}
                                                        >
                                                            <VolumeUpIcon></VolumeUpIcon>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                {/*                                                 
                                                <ListItemText
                                                    primary={soundId}
                                                ></ListItemText>
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            playSoundEffect(
                                                                soundEffects[
                                                                    soundId
                                                                ]
                                                            );
                                                        }}
                                                    >
                                                        <VolumeUpIcon></VolumeUpIcon>
                                                    </IconButton>
                                                </ListItemSecondaryAction> */}
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    label={
                                        <span style={{ fontSize: "12px" }}>
                                            SMS upon page
                                        </span>
                                    }
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            checked={Boolean(person.textOnPage)}
                                            onChange={e => {
                                                person.textOnPage =
                                                    e.target.checked;
                                                handleChange(person);
                                                console.log(person);
                                            }}
                                        ></Switch>
                                    }
                                ></FormControlLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={!person.textOnPage}
                                    InputLabelProps={{ shrink: true }}
                                    label="Phone Number"
                                    value={person.phoneNumber}
                                    onChange={e => {
                                        person.phoneNumber = e.target.value;
                                        handleChange(person);
                                    }}
                                ></TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        style={{
                            color: person.textColor,
                            backgroundColor: person.bgColor
                        }}
                    >
                        <Grid
                            style={{ height: "100%" }}
                            container
                            spacing={0}
                            align="center"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item xs={12}>
                                {person.name}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
