import React, { useState, useEffect, useContext } from "react";
import { Router, Route } from "react-router-dom";
import Billing from "./Billing/Billing";
import {
    Tab,
    Tabs,
    Button,
    Container,
    CardHeader,
    Card,
    Grid
} from "@material-ui/core";
import { mainRouterHistory } from "../../routerHistories";
import Users from "./Users/Users";
import { OfficeContext, useOffice } from "./OfficeContext";
import PeopleIcon from "@material-ui/icons/People";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import ChatIcon from "@material-ui/icons/Chat";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Paging from "./Paging/Paging";
import Messaging from "./Messaging/Messaging";
import PatientTracking from "./PatientTracking/PatientTracking";
import Reports from "./Reports/Reports";
import BillingV2 from "./BillingV2/BillingV2";

export default function(props) {
    const [selectedTab, setSelectedTab] = useState("billing");
    useEffect(() => {
        mainRouterHistory.push(`/office/${selectedTab}`, {
            officeId: props.location.state.officeId,
            officeName: props.location.state.officeName
        });
    }, [selectedTab]);

    return (
        <OfficeContext.Provider
            value={useOffice(
                props.location.state.officeId,
                props.location.state.officeName
            )}
        >
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <CardHeader title={props.location.state.officeName} />
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs
                            variant="scrollable"
                            scrollButtons="auto"
                            // fullwidth
                            // centered
                            value={selectedTab}
                            onChange={(e, newVal) => {
                                setSelectedTab(newVal);
                            }}
                        >
                            <Tab
                                icon={<CreditCardIcon />}
                                value={"billing"}
                                label="billing"
                            />
                            <Tab
                                icon={<PeopleIcon />}
                                value={"users"}
                                label="roles"
                            />
                            <Tab
                                icon={<NotificationImportantIcon />}
                                value={"paging"}
                                label="paging"
                            />

                            <Tab
                                icon={<ChatIcon />}
                                value={"messaging"}
                                label="messaging"
                            />

                            <Tab
                                icon={<TrackChangesIcon />}
                                value={"patientTracking"}
                                label="patient Tracking"
                            />
                            <Tab
                                icon={<AssessmentIcon />}
                                value={"reports"}
                                label="reports"
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                        <Route path="/office/billing" component={Billing} />
                        <Route path="/office/users/" component={Users} />
                        <Route path="/office/paging/" component={Paging} />
                        <Route
                            path="/office/messaging/"
                            component={Messaging}
                        />
                        <Route
                            path="/office/patientTracking"
                            component={PatientTracking}
                        />
                        <Route
                            path="/office/reports"
                            component={Reports}
                        ></Route>
                    </Grid>
                </Grid>
            </Container>
        </OfficeContext.Provider>
    );
}
