import React, { useState, useContext } from "react";
import firebase from "../firebase";
import {
    AppBar,
    Toolbar,
    Modal,
    Grid,
    IconButton,
    Tooltip,
    useTheme
} from "@material-ui/core";
import Login from "./Login/Login";
import { mainRouterHistory } from "../routerHistories";
import { authContext } from "../contexts/auth";
import EjectIcon from "@material-ui/icons/Eject";

export default function(props) {
    const { user } = useContext(authContext);
    const theme = useTheme();
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    let userNav;

    if (user) {
        userNav = (
            <React.Fragment>
                <Grid container align="center" alignItems="center" spacing={1}>
                    <Grid item>
                        <Tooltip title="Logout">
                            <IconButton
                                variant="contained"
                                size="small"
                                style={{ color: "white" }}
                                onClick={() => {
                                    firebase.auth().signOut();
                                    mainRouterHistory.push("/");
                                }}
                            >
                                <EjectIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid
                        item
                        onClick={() => {
                            mainRouterHistory.push("/dashboard");
                        }}
                    >
                        {user.email}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <AppBar position="relative" style={{ width: "100%", margin: 0 }}>
                <Toolbar
                    variant="dense"
                    disableGutters
                    style={{ minHeight: "20px" }}
                ></Toolbar>
            </AppBar>

            <AppBar position="fixed" style={{ width: "100%", margin: 0 }}>
                <Toolbar
                    variant="dense"
                    disableGutters
                    style={{ minHeight: 0 }}
                >
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item>{userNav}</Grid>
                        <Grid item />
                        <Grid item />
                    </Grid>
                </Toolbar>
                <Modal
                    style={{ display: "flex" }}
                    open={loginModalOpen}
                    onClose={() => {
                        setLoginModalOpen(false);
                    }}
                >
                    <Login
                        handleClose={() => {
                            setLoginModalOpen(false);
                        }}
                    />
                </Modal>
            </AppBar>
        </React.Fragment>
    );
}
