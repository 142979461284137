import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    Grid,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import BackspaceIcon from "@material-ui/icons/Backspace";

import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { OfficeContext } from "../../OfficeContext";
import ModifyStage from "./ModifyStage";

export default React.forwardRef((props, ref) => {
    const handleClose = props.handleClose;
    const { error } = useContext(appContext);
    const { getPatientTracking } = useContext(PatientTrackingContext);
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const [stages, setStages] = useState([
        {
            name: "newStage"
        }
    ]);

    const addStageToList = () => {
        setStages([
            ...stages,
            {
                name: "newStage"
            }
        ]);
    };

    const removeStageFromList = () => {
        if (stages.length > 0) {
            setStages(stages.slice(0, stages.length - 1));
        }
    };

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Add Stages" />
            <CardContent>
                <div style={{ margin: "auto" }}>
                    <IconButton
                        onClick={() => {
                            addStageToList();
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            removeStageFromList();
                        }}
                    >
                        <BackspaceIcon />
                    </IconButton>
                </div>
                <Grid
                    container
                    style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                    {stages.map((stage, index) => {
                        return (
                            <ModifyStage
                                key={index}
                                stage={stages[index]}
                                handleChange={stage => {
                                    const newStages = [...stages];
                                    newStages[index] = { ...stage };
                                    setStages(newStages);
                                }}
                            />
                        );
                    })}
                </Grid>
                <Button
                    variant="contained"
                    onClick={() => {
                        setLoading(true);
                        insyncHttps.patientTracking
                            .addStagesToOffice({ officeId, stages })
                            .then(result => {
                                getPatientTracking();
                                setLoading(false);
                                handleClose();
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Submit
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardContent>
        </Card>
    );
});

