import React, { useEffect, useState, useContext } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
    Dialog,
    List,
    ListItem,
    ListItemText,
    Modal
} from "@material-ui/core";
import { BillingContext } from "../BillingContext";
import AddIcon from "@material-ui/icons/Add";
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import AddPaymentSource from "./AddPaymentSource";
import { appContext } from "../../../../contexts/app";

import PaymentSourceDetails from "./PaymentSourceDetails";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";

export default props => {
    const { paymentSources } = useContext(BillingContext);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [viewedSource, setViewedSource] = useState(null);

    let content;
    if (paymentSources.error) {
        content = (
            <ErrorStateDisplay
                title="Error Getting Payment Sources"
                message={paymentSources.error}
                retry={paymentSources.getSources}
            />
        );
    } else {
        content = (
            <React.Fragment>
                {paymentSources.loading ? (
                    <CircularProgress size={30} />
                ) : (
                    <List style={{ height: "200px", overflowY: "auto" }}>
                        {paymentSources.sources.map(paymentSourceItem => {
                            const paymentSource =
                                paymentSourceItem.payment_source;
                            return (
                                <ListItem
                                    button
                                    key={paymentSource.id}
                                    divider
                                    onClick={() => {
                                        setViewedSource(paymentSource);
                                    }}
                                >
                                    {paymentSource.card ? (
                                        <ListItemText
                                            primary={`${paymentSource.card.brand} ending in ${paymentSource.card.last4}`}
                                        />
                                    ) : (
                                        <ListItemText
                                            primary={paymentSource.id}
                                        />
                                    )}
                                </ListItem>
                            );
                        })}
                    </List>
                )}
                <Modal
                    style={{
                        justifyContent: "center",
                        justifyItems: "center",
                        display: "flex"
                    }}
                    open={viewedSource != null}
                    onClose={() => {
                        setViewedSource(null);
                    }}
                >
                    <PaymentSourceDetails paymentSource={viewedSource} />
                </Modal>
                <Dialog
                    open={addDialogOpen}
                    onClose={() => {
                        setAddDialogOpen(false);
                    }}
                >
                    <AddPaymentSource
                        handleClose={() => {
                            setAddDialogOpen(false);
                        }}
                    />
                </Dialog>
            </React.Fragment>
        );
    }

    return (
        <Card style={{ height: "400px" }}>
            <CardHeader
                title="PAYMENT SOURCES"
                action={
                    <React.Fragment>
                        <IconButton
                            onClick={() => {
                                paymentSources.getSources();
                            }}
                        >
                            <AutoRenewIcon />
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                setAddDialogOpen(true);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <CardContent>{content}</CardContent>
        </Card>
    );
};
