import React, { useContext, useState } from "react";

import {
    ListItemText,
    ListItem,
    List,
    CircularProgress,
    Grid,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    Card,
    Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

import { appContext } from "../../../../contexts/app";
import EditStage from "./EditStage";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { OfficeContext } from "../../OfficeContext";

export default function(props) {
    const { stage, stageId, moveStageUp, moveStageDown } = props;
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { getPatientTracking, defaults } = useContext(PatientTrackingContext);
    const [editStageOpen, setEditStageOpen] = useState(false);
    const isDefault =
        defaults && defaults.stageId ? defaults.stageId === stageId : false;
    const backgroundColor = stage.backgroundColor || "white";
    const textColor = stage.textColor || "black";

    return (
        <Grid item xs={12} lg={5}>
            <List>
                <Card
                    style={{
                        border: "1px solid black",
                        backgroundColor: backgroundColor,
                        color: textColor
                    }}
                >
                    <ListItemText primary={stage.name} />
                    <IconButton
                                 style={{
                                    backgroundColor: "white"
                                }}
                        size="small"
                        onClick={() => {
                            setEditStageOpen(true);
                        }}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>

                    {!isDefault && (
                        <Tooltip title={"set as default"}>
                            <IconButton
                                         style={{
                                            backgroundColor: "white"
                                        }}
                                size="small"
                                onClick={() => {
                                    setLoading(true);
                                    insyncHttps.patientTracking
                                        .setDefaultStage({
                                            officeId,
                                            stageId
                                        })
                                        .then(result => {
                                            setLoading(false);
                                            getPatientTracking();
                                        })
                                        .catch(err => {
                                            setLoading(false);
                                            error.setMessage(err.message);
                                        });
                                }}
                            >
                                <BookmarkBorderIcon></BookmarkBorderIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {isDefault && (
                        <Tooltip title={"default"}>
                            <IconButton
                                size="small"
                                style={{
                                    backgroundColor: "white"
                                }}
                            >
                                <BookmarkIcon></BookmarkIcon>
                            </IconButton>
                        </Tooltip>
                    )}

                    <IconButton
                    
                        onClick={() => {
                            setLoading(true);
                            insyncHttps.patientTracking
                                .removeStageFromOffice({ officeId, stageId })
                                .then(result => {
                                    setLoading(false);
                                    getPatientTracking();
                                })
                                .catch(err => {
                                    setLoading(false);
                                    error.setMessage(err.message);
                                });
                        }}
                        size="small"
                        style={{
                            backgroundColor: "white"
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    {loading && <CircularProgress size={10} />}
                </Card>
            </List>
            <Dialog
                open={editStageOpen}
                onClose={() => {
                    setEditStageOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <EditStage
                            stageId={stageId}
                            handleClose={() => {
                                setEditStageOpen(false);
                            }}
                            stage={stage}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
