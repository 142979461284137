import React, { createContext, useState } from "react";

export const OfficeContext = createContext({
    officeId: null,
    officeName: null,
    setOfficeName: () => {},
    setOfficeId: () => {}
});

export const useOffice = (id, name) => {
    const [officeId, setOfficeId] = useState(id);
    const [officeName, setOfficeName] = useState(name);

    return { officeId, setOfficeId, officeName, setOfficeName };
};
