import React, { useContext, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    Grid
} from "@material-ui/core";
import { OfficeContext } from "../OfficeContext";
import { appContext } from "../../../contexts/app";
import { MessagingContext } from "./MessagingContext";
import insyncHttps from "../../../insyncHttps/insyncHttps";

export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const [loading, setLoading] = useState(false);
    const messaging = useContext(MessagingContext);
    const numberOfMessages = Object.keys(messaging.messages).length;
    const latesMessage = Object.keys(messaging.messages).reduce(
        (acc, messageId) => {
            const message = messaging.messages[messageId];
            if (message.messageTime > acc) {
                acc = message.messageTime;
            }
            return acc;
        },
        0
    );
    return (
        <Card>
            <CardHeader title="Messages" />
            <CardContent>
                {messaging.loading ? (
                    <CircularProgress size={30} />
                ) : (
                    <div>
                        <Grid container style={{ textAlign: "left" }}>
                            <Grid item xs={6}>
                                Messages:
                            </Grid>
                            <Grid item xs={6}>
                                {numberOfMessages}
                            </Grid>
                            <Grid item xs={6}>
                                Latest Message:
                            </Grid>
                            <Grid item xs={6}>
                                {new Date(latesMessage).toISOString()}
                            </Grid>
                        </Grid>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLoading(true);
                                insyncHttps.messaging
                                    .deletAllMessages({ officeId })
                                    .then(result => {
                                        setLoading(false);
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        console.error(err);
                                        error.setMessage(err.message);
                                    });
                            }}
                        >
                            Delete Messages
                        </Button>
                    </div>
                )}
                {loading && <CircularProgress size={10} />}
            </CardContent>
        </Card>
    );
}
