import React, { useContext } from "react";

import ColumnListItem from "./ColumnListItem";
import ColumnLayoutContext from "./ColumnLayoutContext";

export default function(props) {
    const columnLayout = useContext(ColumnLayoutContext);
    const sortedColumnIds = Object.keys(
        columnLayout.localState.trackerColumns
    ).sort((idA, idB) => {
        const columnA = columnLayout.localState.trackerColumns[idA];
        const columnB = columnLayout.localState.trackerColumns[idB];

        return columnA.order - columnB.order;
    });

    return sortedColumnIds.map(columnId => {
        return (
            <ColumnListItem key={columnId} columnId={columnId}></ColumnListItem>
        );
    });
}
