import React, { useEffect, useContext } from "react";
import Users from "./Users";
import { MessagingContext, useMessaging } from "./MessagingContext";
import { OfficeContext } from "../OfficeContext";
import { Grid } from "@material-ui/core";
import Messages from "./Messages";

export default function(props) {
    const { officeId } = useContext(OfficeContext);
    const messaging = useMessaging(officeId);
    useEffect(() => {
        messaging.getMessaging();
    }, []);

    return (
        <MessagingContext.Provider value={messaging}>
            <Grid container align="center" justify="center" spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Users users={messaging.users} />
                </Grid>
                {/* <Grid item xs={12} lg={6}>
                    <Messages />
                </Grid> */}
            </Grid>
        </MessagingContext.Provider>
    );
}
