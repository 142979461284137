import { useState, useEffect } from "react";

export default function(props) {
    const [trackerColumns, setTrackerColumns] = useState({});
    const [modified, setModified] = useState(false);

    function setColumnEnabled(columnId, enabled) {
        trackerColumns[columnId].enabled = Boolean(enabled);
        setTrackerColumns({ ...trackerColumns });
        setModified(true);
    }

    function moveColumnUp(columnId) {
        const sortedColumns = getSortedColumnArray();

        sortedColumns.forEach((column, index) => {
            column.order = index;
        });

        const currentIndex = sortedColumns.findIndex(column => {
            return column.id === columnId;
        });

        if (currentIndex <= 0) {
            return;
        } else {
            sortedColumns[currentIndex - 1].order = currentIndex;
            sortedColumns[currentIndex].order = currentIndex - 1;
        }

        const newTrackerColumns = (() => {
            const newObj = {};
            sortedColumns.forEach(column => {
                newObj[column.id] = column;
            });
            return newObj;
        })();

        setTrackerColumns(newTrackerColumns);
        setModified(true);
    }

    function moveColumnDown(columnId) {
        const sortedColumns = getSortedColumnArray();
        sortedColumns.forEach((column, index) => {
            column.order = index;
        });

        const currentIndex = sortedColumns.findIndex(column => {
            return column.id === columnId;
        });

        if (currentIndex >= sortedColumns.length - 1) {
            return;
        } else {
            sortedColumns[currentIndex + 1].order = currentIndex;
            sortedColumns[currentIndex].order = currentIndex + 1;
        }

        const newTrackerColumns = (() => {
            const newObj = {};
            sortedColumns.forEach(column => {
                newObj[column.id] = column;
            });
            return newObj;
        })();

        setTrackerColumns({ ...newTrackerColumns });
        setModified(true);
    }

    function getSortedColumnArray() {
        return Object.keys(trackerColumns)
            .sort((columnIdA, columnIdB) => {
                const columnA = trackerColumns[columnIdA];
                const columnB = trackerColumns[columnIdB];

                return columnA.order - columnB.order;
            })
            .map(columnId => {
                return trackerColumns[columnId];
            });
    }

    return {
        setTrackerColumns,
        trackerColumns,
        moveColumnDown,
        moveColumnUp,
        setColumnEnabled,
        modified,
        setModified
    };
}
