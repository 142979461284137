import React, { useState, useContext } from "react";
import { Card, CardContent, TextField, Grid, RadioGroup, Radio, FormControlLabel, Button, Menu, CardHeader } from "@material-ui/core";
import { SketchPicker } from "react-color";

export default function(props) {
    const stage = props.stage;
    const handleChange = props.handleChange;
    const [bgColorAnchorEl, setBgColorAnchorEl] = useState(null);

    return (
        <Card style={{ width: "100%" }}>
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    align="center"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <Grid container justify="center" align="center">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="name"
                                    value={stage.name}
                                    onChange={e => {
                                        stage.name = e.target.value;
                                        handleChange(stage);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    value={stage.textColor}
                                    onChange={e => {
                                        stage.textColor = e.target.value;
                                        handleChange(stage);
                                    }}
                                >
                                    <FormControlLabel
                                        value={"#000000"}
                                        control={<Radio />}
                                        label="Black Text"
                                    />
                                    <FormControlLabel
                                        value={"#FFFFFF"}
                                        control={<Radio />}
                                        label="White Text"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={e => {
                                        setBgColorAnchorEl(e.currentTarget);
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={6}>
                                            BG color:
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            style={{
                                                backgroundColor: stage.backgroundColor,
                                                color: stage.textColor
                                            }}
                                        >
                                            {stage.backgroundColor}
                                        </Grid>
                                    </Grid>
                                </Button>
                                <Menu
                                    anchorEl={bgColorAnchorEl}
                                    open={Boolean(bgColorAnchorEl)}
                                    onClose={() => {
                                        setBgColorAnchorEl(null);
                                    }}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                >
                                    <Card>
                                        <CardHeader title="BG Color" />
                                        <SketchPicker
                                            color={stage.backgroundColor}
                                            onChangeComplete={color => {
                                                stage.backgroundColor = color.hex;
                                                handleChange(stage);
                                            }}
                                        />
                                    </Card>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
