import React, { useContext, useState, useEffect } from "react";
import { PagingContext } from "../PagingContext";
import {
    Card,
    CardHeader,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    CardActions,
    Dialog,
    Button
} from "@material-ui/core";
import AddMeaningButtons from "./AddMeaningButtons";
import AddIcon from "@material-ui/icons/Add";
import MeaningButtonListItem from "./MeaningButtonListItem";
import { reorderMeaningButtons } from "../../../../insyncHttps/paging/paging";
import { OfficeContext } from "../../OfficeContext";

export default function(props) {
    const { meaningButtons, loading, getPaging } = useContext(PagingContext);
    const { officeId } = useContext(OfficeContext);
    const [addMeaningButtonsOpen, setAddMeaningButtonsOpen] = useState(false);
    const [orderedMeaningButtonIds, setOrderedMeaningButtonIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);

    function moveMeaningButtonUp(meaningButtonId) {
        const currentIndex = orderedMeaningButtonIds.indexOf(meaningButtonId);
        if (currentIndex > 0) {
            orderedMeaningButtonIds.splice(currentIndex, 1);
            orderedMeaningButtonIds.splice(
                currentIndex - 1,
                0,
                meaningButtonId
            );
            setOrderModified(true);
            setOrderedMeaningButtonIds([...orderedMeaningButtonIds]);
        }
    }

    function moveMeaningButtonDown(meaningButtonId) {
        const currentIndex = orderedMeaningButtonIds.indexOf(meaningButtonId);
        if (currentIndex < orderedMeaningButtonIds.length - 1) {
            orderedMeaningButtonIds.splice(currentIndex, 1);
            orderedMeaningButtonIds.splice(
                currentIndex + 1,
                0,
                meaningButtonId
            );
            setOrderedMeaningButtonIds([...orderedMeaningButtonIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        setOrderModified(false);
        setOrderedMeaningButtonIds(getSortedMeaningButtonIds(meaningButtons));
    }, [meaningButtons]);

    return (
        <Card>
            <CardHeader title="MeaningButtons" />
            <CardContent>
                <Grid
                    container
                    spacing={1}
                    justify="center"
                    style={{ height: 300, overflowY: "scroll" }}
                >
                    {loading ? (
                        <CircularProgress size={20} />
                    ) : (
                        orderedMeaningButtonIds.map(meaningButtonId => {
                            const meaningButton =
                                meaningButtons[meaningButtonId];
                            if (!meaningButtons[meaningButtonId]) {
                                return null;
                            }
                            return (
                                <MeaningButtonListItem
                                    key={meaningButtonId}
                                    {...{
                                        meaningButton,
                                        meaningButtonId,
                                        moveMeaningButtonDown,
                                        moveMeaningButtonUp
                                    }}
                                />
                            );
                        })
                    )}
                </Grid>
            </CardContent>
            <CardActions>
                <IconButton
                    onClick={() => {
                        setAddMeaningButtonsOpen(true);
                    }}
                >
                    <AddIcon />
                </IconButton>
                {orderModified && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSaveOrderLoading(true);
                            reorderMeaningButtons({
                                officeId,
                                meaningButtonIds: orderedMeaningButtonIds
                            })
                                .then(result => {
                                    setSaveOrderLoading(false);
                                    getPaging();
                                })
                                .catch(err => {
                                    setSaveOrderLoading(false);
                                    getPaging();
                                });
                        }}
                    >
                        save order
                        {saveOrderLoading && (
                            <CircularProgress size={10}></CircularProgress>
                        )}
                    </Button>
                )}
            </CardActions>
            <Dialog
                open={addMeaningButtonsOpen}
                onClose={() => {
                    setAddMeaningButtonsOpen(false);
                }}
            >
                <Grid container justify="center" align="center">
                    <Grid item xs={12}>
                        <AddMeaningButtons
                            handleClose={() => {
                                setAddMeaningButtonsOpen(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </Card>
    );
}

function getSortedMeaningButtonIds(meaningButtons) {
    return Object.keys(meaningButtons)
        .filter(meaningButtonId => {
            return Object.keys(meaningButtons).includes(meaningButtonId);
        })
        .sort((a, b) => {
            if (meaningButtons[a].name > meaningButtons[b].name) {
                return 1;
            }
            if (meaningButtons[a].name < meaningButtons[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const meaningButtonA = { ...meaningButtons[a] };
            const meaningButtonB = { ...meaningButtons[b] };
            meaningButtonA.listPosition = meaningButtonA.listPosition
                ? meaningButtonA.listPosition
                : 0;
            meaningButtonB.listPosition = meaningButtonB.listPosition
                ? meaningButtonB.listPosition
                : 0;

            return meaningButtonA.listPosition - meaningButtonB.listPosition;
        });
}
