import React, { useState, useContext } from "react";
import {
    CardContent,
    Card,
    CardHeader,
    Grid,
    Button,
    CircularProgress
} from "@material-ui/core";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";
import { PagingContext } from "../PagingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import ModifyMeaningButton from "./ModifyMeaningButton";

export default function(props) {
    const { getPaging } = useContext(PagingContext);
    const { error } = useContext(appContext);
    const handleClose = props.handleClose;
    const meaningButtonId = props.meaningButtonId;
    const [meaningButton, setMeaningButton] = useState({
        ...props.meaningButton
    });
    const [loading, setLoading] = useState(false);
    const { officeId } = useContext(OfficeContext);
    const ref = props.ref;

    return (
        <Card ref={ref} style={{ margin: "auto" }}>
            <CardHeader title="Edit MeaningButton" />
            <CardContent>
                <Grid
                    container
                    style={{ maxHeight: "400px", overflowY: "scroll" }}
                >
                    <ModifyMeaningButton
                        meaningButton={meaningButton}
                        handleChange={p => {
                            setMeaningButton({ ...p });
                        }}
                    ></ModifyMeaningButton>
                </Grid>
                <Button
                    variant="contained"
                    onClick={() => {
                        const req = {
                            officeId,
                            meaningButtonId,
                            ...meaningButton
                        };
                        setLoading(true);
                        insyncHttps.paging
                            .updateMeaningButton(req)
                            .then(result => {
                                setLoading(false);
                                getPaging();
                                handleClose();
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                                error.setMessage(err.message);
                            });
                    }}
                >
                    Submit
                </Button>
                {loading && <CircularProgress size={10} />}
            </CardContent>
        </Card>
    );
}
