import React, { useContext, useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    CircularProgress,
    Grid,
    MenuItem
} from "@material-ui/core";
import { PatientTrackingContext } from "../PatientTrackingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { OfficeContext } from "../../OfficeContext";
import { appContext } from "../../../../contexts/app";

export default function(props) {
    const { defaults, getPatientTracking, loading, visitSettings } = useContext(
        PatientTrackingContext
    );
    const { officeId } = useContext(OfficeContext);
    const [appointmentLength, setAppointmentLength] = useState(
        parseInt(defaults.appointmentLength) || 30
    );
    const [countFrom, setCountFrom] = useState(
        visitSettings.countFrom || "appointmentTime"
    );
    const [requesting, setRequesting] = useState(false);
    const { error } = useContext(appContext);

    useEffect(() => {
        setAppointmentLength(parseInt(defaults.appointmentLength) || 30);
        setCountFrom(visitSettings.countFrom || "appointmentTime");
    }, [defaults, visitSettings]);

    const modified =
        appointmentLength !== defaults.appointmentLength ||
        countFrom !== visitSettings.countFrom;

    return (
        <Card>
            <CardHeader title={"Visit Settings"}></CardHeader>
            <CardContent>
                {loading ? (
                    <CircularProgress></CircularProgress>
                ) : (
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    label="Default Appt. Length"
                                    value={appointmentLength}
                                    onChange={e => {
                                        setAppointmentLength(e.target.value);
                                    }}
                                ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    // disabled={counterType !== "countDown"}
                                    select
                                    label="Count From"
                                    value={countFrom}
                                    onChange={e => {
                                        setCountFrom(e.target.value);
                                    }}
                                >
                                    <MenuItem value={"appointmentTime"}>
                                        Appointment Time
                                    </MenuItem>
                                    <MenuItem value={"checkInTime"}>
                                        Check-in Time
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disabled={!modified}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setRequesting(true);
                                        insyncHttps.patientTracking
                                            .updateVisitSettings({
                                                officeId,
                                                appointmentLength,
                                                countFrom
                                            })
                                            .catch(e => {
                                                setRequesting(false);
                                                error.setMessage(e);
                                            })
                                            .then(e => {
                                                setRequesting(false);
                                                getPatientTracking();
                                            });
                                    }}
                                >
                                    update
                                </Button>
                                {requesting && (
                                    <CircularProgress></CircularProgress>
                                )}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    );
}
