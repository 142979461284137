import firebase from "../../firebase";

export default function({ officeId, counterType, countFrom }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "patientTrackingAdmin",
        funcName: "updateVisitDisplaySettings",
        data: {
            officeId,
            counterType,
            countFrom
        }
    });
}
