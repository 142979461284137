import React, { useContext, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Card,
    Table,
    TableBody,
    TableRow,
    TableCell,
    CardContent,
    CardHeader,
    IconButton,
    CircularProgress,
    Dialog,
    Modal,
    List,
    ListItemText,
    ListItem,
    ListItemSecondaryAction
} from "@material-ui/core";
import { BillingContext } from "../BillingContext";
import AddSubscription from "./AddSubscription";
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import SubscriptionDetails from "./SubscriptionDetails";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";

export default function Subscriptions(props) {
    const { subscriptions } = useContext(BillingContext);
    const { subs, loading } = subscriptions;
    const [addSubDialogOpen, setAddSubDialogOpen] = useState(false);
    const [viewedSubscription, setViewedSubscription] = useState(null);

    let content;
    if (subscriptions.error) {
        content = (
            <ErrorStateDisplay
                title="Error Getting Subscriptions"
                message={subscriptions.error}
                retry={subscriptions.getSubs}
            />
        );
    } else {
        content = (
            <React.Fragment>
                {loading ? (
                    <CircularProgress size={30} />
                ) : (
                    <List style={{ height: "200px", overflowY: "auto" }}>
                        {subs
                            .sort((a, b) => {
                                const ranks = {
                                    active: 3,
                                    non_renewing: 2
                                };
                                if (a.status !== b.status) {
                                    if (
                                        (ranks[a.status] || 0) >
                                        (ranks[b.status] || 0)
                                    ) {
                                        return -1;
                                    } else {
                                        return 1;
                                    }
                                } else {
                                    if (a.id > b.id) {
                                        return 1;
                                    } else {
                                        return -1;
                                    }
                                }
                            })
                            .map(sub => {
                                return (
                                    // <div>
                                    //     <pre>
                                    //         {JSON.stringify(sub, null, 4)}
                                    //     </pre>
                                    // </div>
                                    <ListItem
                                        button
                                        divider
                                        key={sub.id}
                                        onClick={() => {
                                            setViewedSubscription(sub);
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    {sub.plan_id}
                                                </React.Fragment>
                                            }
                                            secondary={`${
                                                sub.plan_quantity
                                            } * ${(
                                                sub.plan_unit_price / 100
                                            ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            })} = ${(
                                                sub.plan_amount / 100
                                            ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD"
                                            })}`}
                                        />
                                        <ListItemSecondaryAction>
                                            <span
                                                style={{
                                                    color:
                                                        sub.status === "active"
                                                            ? "green"
                                                            : "red"
                                                }}
                                            >
                                                {sub.status}
                                            </span>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                    </List>
                )}
                <Modal
                    open={viewedSubscription != null}
                    onClose={() => {
                        setViewedSubscription(null);
                    }}
                    style={{
                        justifyContent: "center",
                        justifyItems: "center",
                        display: "flex"
                    }}
                >
                    <SubscriptionDetails
                        handleClose={() => {
                            setViewedSubscription(null);
                        }}
                        subscription={viewedSubscription}
                    />
                </Modal>
                <Dialog
                    open={addSubDialogOpen}
                    onClose={() => {
                        setAddSubDialogOpen(false);
                    }}
                >
                    <AddSubscription
                        handleClose={() => {
                            setAddSubDialogOpen(false);
                        }}
                    />
                </Dialog>
            </React.Fragment>
        );
    }
    return (
        <Card style={{ height: "400px" }}>
            <CardHeader
                title="SUBSCRIPTIONS"
                action={
                    <React.Fragment>
                        <IconButton
                            onClick={() => {
                                subscriptions.getSubs();
                            }}
                        >
                            <AutoRenewIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                setAddSubDialogOpen(true);
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <CardContent>{content}</CardContent>
        </Card>
    );
}
