import React, { useContext, useEffect, useState } from "react";
import VisitReport1Context from "../../VisitReport1Context";
import { VictoryPie, VictoryLabel } from "victory";
import { Card, CardContent } from "@material-ui/core";

export default function(props) {
    const reportCtx = useContext(VisitReport1Context);
    const [slices, setSlices] = useState([]);

    useEffect(() => {
        setSlices(createSlices());
    }, [reportCtx.visits]);

    function getStageById(id) {
        return reportCtx.stages.find(stage => {
            return stage.id === id;
        });
    }

    function createSlices() {
        //get cumulative total of time spent in each stage
        const combinedStageTimes = {};
        reportCtx.visits.filter(visit=>{return visit.completedTime && visit.completedTime > 0}).forEach(visit => {
            Object.keys(visit.stageTimes).forEach(stageId => {
                if (!combinedStageTimes[stageId]) {
                    combinedStageTimes[stageId] = {
                        id: stageId,
                        totalTime: visit.stageTimes[stageId]
                    };
                } else {
                    combinedStageTimes[stageId].totalTime +=
                        visit.stageTimes[stageId];
                }
            });
        });

        const sliceData = Object.keys(combinedStageTimes).map(
            combinedStageTimesId => {
                const element = combinedStageTimes[combinedStageTimesId];
                const stage = getStageById(element.id);
                element.name = stage && stage.name ? stage.name : element.id;
                element.backgroundColor =
                    stage && stage.backgroundColor
                        ? stage.backgroundColor
                        : "grey";
                element.textColor =
                    stage && stage.textColor ? stage.textColor : "black";
                element.value = element.totalTime;
                return element;
            }
        );

        const sumOfTime = sliceData.reduce((acc, curr) => {
            return acc + curr.totalTime;
        }, 0);

        sliceData.forEach(element => {
            element.percent = Math.round((element.totalTime / sumOfTime) * 100);
        });

        return sliceData;
    }

    return (
        <Card>
            <CardContent>
                <h2 style={{ textAlign: "center" }}>Average Percent of Visit Spent in Stage</h2>
                <div style={{ maxWidth: "20vw", margin: "0 auto" }}>
                    <VictoryPie
                        style={{
                            labels: { fontSize: 20 },
                            parent: { overflow: "visible" }
                        }}
                        events={[
                            {
                                target: "data",
                                eventHandlers: {
                                    onClick: (evt, props) => {
                                        const { datum } = props;
                                        alert(datum.name);
                                    }
                                }
                            }
                        ]}
                        name="Time Per Stage"
                        height={400}
                        width={400}
                        colorScale={(() => {
                            return slices.map(slice => {
                                return slice.backgroundColor;
                            });
                        })()}
                        labels={({ datum }) => {
                            return `${datum.name}
                            ${datum.percent}%`;
                        }}
                        labelComponent={
                            <VictoryLabel renderInPortal></VictoryLabel>
                        }
                        data={slices.map(slice => {
                            // return { ...slice, y: slice.value };
                            return { ...slice, y: slice.value };
                        })}
                        innerRadius={60}
                    ></VictoryPie>
                </div>
            </CardContent>
        </Card>
    );
}
